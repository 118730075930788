const styles = () => ({
  Container: {
    paddingTop: 40,
  },
  CarouselWrapper: {
    padding: 10,
  },
  ProductWrapper: {
    padding: 10,
  },
  AdditionalProductsTitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  ButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

export default styles;
