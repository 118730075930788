import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import LeftArrow from 'images/left-arrow.png';

import styles from './style';

const useStyles = makeStyles(styles);

const GoHomeButton = () => {
  const classes = useStyles();
  return (
    <Button size='small' className={classes.ButtonAmazon}>
      <Link to='/' className={classes.Link}>
        <div className={classes.ButtonWrapper}>
          <h3 className={classes.ButtonText}>
            <img src={LeftArrow} alt='back to best seller' />
            <span>back to best seller</span>
          </h3>
        </div>
      </Link>
    </Button>
  );
};

export default GoHomeButton;
