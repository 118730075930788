const styles = () => ({
  PriceWrapper: {
    fontWeight: 'bold',
  },
  RichTextWrapper: {
    lineHeight: '2',
    textAlign: 'justify',
  },
});

export default styles;
