import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import styles from './style';

const useStyles = makeStyles(styles);

const AmazonButton = ({ productLink }) => {
  const classes = useStyles();
  return (
    <a
      href={productLink}
      target='_blank'
      style={{ textDecoration: 'none' }}
      rel='noreferrer'
    >
      <Button size='small' className={classes.ButtonAmazon}>
        <div className={classes.ButtonWrapper}>
          <h2 className={classes.AmazonText}>By on AMAZON</h2>
        </div>
      </Button>
    </a>
  );
};

export default AmazonButton;
