const styles = () => ({
  ButtonAmazon: {
    maxWidth: 400,
    marginTop: 20,
    marginBottom: 30,
    border: '1px solid black',
    padding: '10px 10px',
    '&:hover': {
      background: 'white',
    },
  },
  ButtonText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    '& img': {
      width: 30,
    },
    '& span': {
      paddingLeft: 20,
      textTransform: 'uppercase',
    },
  },
  Link: {
    textDecoration: 'none',
    color: 'black',
  },
});

export default styles;
