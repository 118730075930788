import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContentfulContent from '../../contentfulContent/ContentfulContent';
import AmazonButton from '../../amazonButton/AmazonButton';

import styles from './style';

const useStyles = makeStyles(styles);

const ProductDetails = ({ description, price, title, productLink }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.DescriptionWrapper}>
        <h1>{title}</h1>
      </div>
      {productLink && price > 0 && (
        <>
          <p className={classes.PriceWrapper}>
            <span>$</span>
            {price}
            <span> USD</span>
          </p>

          <AmazonButton productLink={productLink} />
        </>
      )}

      <div className={classes.RichTextWrapper}>
        <ContentfulContent content={description.json} />
      </div>
    </>
  );
};

export default ProductDetails;
