const styles = ({ palette }) => ({
  ButtonAmazon: {
    width: '100%',
    background: palette.primary.colorTheme,
    margin: '20px 0px',
    '&:hover': {
      backgroundColor: '#606060',
    },
  },
  AmazonText: {
    color: 'white',
  },
});

export default styles;
