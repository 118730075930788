import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const VideoRender = ({ video }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.VideoWrapper}>
        <video
          autoPlay
          playsInline
          id='video_background'
          preload='auto'
          // poster='/videos/globe-mobile.png'
          loop
          muted
        >
          <source src={`http:${video}`} type='video/mp4' />
        </video>
      </div>
    </>
  );
};

export default VideoRender;
