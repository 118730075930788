import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import ProductCard from '../productCard/ProductCard';
import styles from './style';

const ProductsRenderContainer = ({ classes, title, mayLike }) => {
  return (
    <Container className={classes.productsWrapper}>
      <h2 className={classes.Title}>{title}</h2>

      <div className={classes.cardsWrapper}>
        {mayLike.map((item) => {
          return (
            <ProductCard
              img={item.previewImage}
              title={item.title}
              key={item.title}
              shortDescription={item.shortDescription}
              url={item.url}
              logo={item.amazonLogo}
              productLink={item.productLink}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default withStyles(styles)(ProductsRenderContainer);
