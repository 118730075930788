import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import { makeStyles } from '@material-ui/core/styles';
import styles from './style';
import VideoRender from '../../videoRender/VideoRender';
import RightFoot from '../../../images/svg/foot.svg';

const useStyles = makeStyles(styles);

const SampleNextArrow = ({ onClick }) => {
  const classes = useStyles();
  return (
    <button className={classes.RightButton} onClick={onClick} type='button'>
      <RightFoot />
    </button>
  );
};

function SamplePrevArrow({ onClick }) {
  const classes = useStyles();
  return (
    <button className={classes.LeftButton} onClick={onClick} type='button'>
      <RightFoot />
    </button>
  );
}

const ProductCarousel = ({ images, videos, isMobile }) => {
  const classes = useStyles();
  const [imageArr, setImageArr] = useState([]);
  const desktopVideo =
    videos && videos?.videoDesktop ? videos?.videoDesktop : null;
  const mobileVideo =
    videos && videos?.videoMobile ? videos?.videoMobile : null;

  useEffect(
    () =>
      setImageArr(
        images.map((el, index) => ({
          fluid: el.fluid,
          index,
        })),
      ),
    [images, setImageArr],
  );

  return (
    <div className={classes.CarouselWrapper}>
      <Slider
        customPaging={(i) => (
          <a key={i} href='/'>
            <Img fluid={imageArr.find((e) => e.index === i).fluid} alt='' />
          </a>
        )}
        dots
        dotsClass='slick-dots slick-thumb'
        infinite
        autoplay
        speed={2000}
        autoplaySpeed={5000}
        slidesToShow={1}
        slidesToScroll={1}
        arrows
        mobileFirst
        centerPadding
        accessibility
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
      >
        {imageArr.map((image, index) => (
          <div key={index}>
            <Img fluid={image.fluid} alt='' />
          </div>
        ))}
      </Slider>
      {!isMobile && desktopVideo && <VideoRender video={desktopVideo} />}
      {isMobile && mobileVideo && <VideoRender video={mobileVideo} />}
    </div>
  );
};

export default ProductCarousel;
