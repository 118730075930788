const styles = ({ palette }) => ({
  CarouselWrapper: {
    '& video': {
      width: '100%',
    },
    '& .gatsby-image-wrapper': {
      margin: 'auto',
      borderRadius: '5px',
    },
    '& .slick-dots': {
      position: 'initial',
      margin: '2rem 0rem',
      '& li': {
        width: '70px !important',
        height: '70px !important',
        '& a': {
          '& div': {
            width: '70px !important',
            height: '70px !important',
            borderRadius: '15px',
          },
        },
      },
    },
    '& .slick-arrow': {
      background: 'red',
    },
  },
  RightButton: {
    position: 'absolute',
    top: '30%',
    right: 0,
    zIndex: 1,
    outline: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 15,
    transition: 'all 0.1s linear',
    '&:hover': {
      transform: 'scale(1.1)',
      '& > svg > g > path:last-child': {
        fill: palette.footHover,
      },
    },
  },
  LeftButton: {
    position: 'absolute',
    top: '30%',
    left: 0,
    zIndex: 1,
    outline: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 15,
    transform: 'scale(-1, 1)',
    '&:hover': {
      transform: 'scale(-1.1, 1.1)',
      '& > svg > g > path:last-child': {
        fill: palette.footHover,
      },
    },
  },
});

export default styles;
