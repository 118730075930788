import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { compose, isMobileView } from 'utils';
import withWidth from '@material-ui/core/withWidth';
import ProductsCarousel from './productCarousel/ProductCarousel';
import ProductDescription from './productDescription/ProductDescription';
import ProductRenderContainer from '../productsRenderContainer/ProductsRenderContainer';
import GoHomeButton from '../goHomeButton/GoHomeButton';

import styles from './style';

const useStyles = makeStyles(styles);

const ProductDetails = ({ product, mayLike, width }) => {
  const classes = useStyles();
  const isMobile = isMobileView(width);

  const description = product ? product.description : {};
  const price = product && product.price > 0 ? product.price : 0;
  const images = product ? product.images : [];
  const title = product ? product.title : '';
  const productLink = product ? product.productLink : '';
  const videos = {
    videoDesktop: product ? product?.productVideo?.file?.url : '',
    videoMobile: product ? product?.productVideoMobile?.file?.url : '',
  };
  return (
    <Container>
      <Grid container className={classes.Container}>
        <Grid item xs={12} md={6} className={classes.CarouselWrapper}>
          <ProductsCarousel
            images={images}
            videos={videos}
            isMobile={isMobile}
          />
        </Grid>
        <Grid item s={12} md={6} className={classes.ProductWrapper}>
          <ProductDescription
            description={description}
            price={price}
            title={title}
            productLink={productLink}
          />
        </Grid>
        <Grid item xs={12} className={classes.CarouselWrapper}>
          <ProductRenderContainer title='You may also like' mayLike={mayLike} />
          <div className={classes.ButtonWrapper}>
            <GoHomeButton />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default compose(withWidth())(ProductDetails);
