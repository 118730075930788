const styles = () => ({
  productsWrapper: {
    margin: 'auto',
    padding: 0,
  },
  Title: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  cardsWrapper: {
    marginTop: '4rem',
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'wrap',
  },
});

export default styles;
