import React from 'react';

import { Layout, SEO } from 'components';
import { useMatch } from '@reach/router';
import ProductDetails from '../../containers/productDetails/ProductDetails';
import getHomeData from '../../queries/home.module';

const ProductPage = () => {
  const match = useMatch('/product/:id');
  const { id } = match;

  const { allContentfulProducts } = getHomeData();
  const products = allContentfulProducts?.edges[0]?.node?.typeOfProducts;
  const product = products.find((p) => p.url === id);

  const mayLike = products.filter((i) => i.id !== product?.id).slice(0, 5);

  return (
    <Layout>
      <SEO title={product?.title} image={product?.previewImage?.fluid?.src} />
      <ProductDetails product={product} mayLike={mayLike} />
    </Layout>
  );
};

export default ProductPage;
